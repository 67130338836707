import React, { useState } from "react";
import * as Icon from "react-feather";
import { useTranslation } from "react-i18next";
import ModalVideo from "react-modal-video";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import "../../node_modules/react-modal-video/scss/modal-video.scss";

import BackgroundImage1 from "../assets/images/bg/hero-bg.jpg";

export default function SectionHome() {
  const { t } = useTranslation();
  const [videoModal, setVideoModal] = useState(false);

  const openModal = () => {
    setVideoModal(true);
  };

  return (
    <>
      <section
        className="bg-home d-flex align-items-center"
        style={{ background: `url(${BackgroundImage1})` }}
        id="home"
      >
        <div className="bg-overlay bg-linear-gradient-3"></div>
        <Container>
          <Row>
            <Col>
              <div className="title-heading">
                <h1 className="heading text-white title-dark mb-4">
                  {t("section_home.title.t1")} <br />{" "}
                  {t("section_home.title.t2")}
                </h1>
                <p className="para-desc text-white-50">
                  {t("section_home.desc")}
                </p>
                <div className="mt-4 pt-2">
                  <Link
                    to="#"
                    data-type="youtube"
                    data-id="yba7hPeTSjk"
                    onClick={openModal}
                    className="btn btn-icon btn-pills btn-primary m-1 lightbox"
                  >
                    <Icon.Video className="icons" />
                  </Link>
                  <span className="text-uppercase text-white-50 small align-middle ms-2">
                    {t("section_home.watch_now")}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <ModalVideo
        channel="youtube"
        isOpen={videoModal}
        youtube={{
          autoplay: 0,
        }}
        videoId="m76VIHXs1QY"
        onClose={() => setVideoModal(false)}
      />
    </>
  );
}
