/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ModalVideo from "react-modal-video";
import { Link } from "react-router-dom";
import { Card, Col, Container, Row } from "reactstrap";
import "../../node_modules/react-modal-video/scss/modal-video.scss";

import AboutImage from "../assets/images/hero-about.jpg";
import MapImage from "../assets/images/map.png";

export default function SectionAboutUs() {
  const { t, i18n } = useTranslation();
  const [videoModal, setVideoModal] = useState(false);

  const openModal = () => {
    setVideoModal(true);
  };

  const strenghts = () => [
    {
      id: 1,
      title: t("section_about_us.strengths.s1.title"),
      value: t("section_about_us.strengths.s1.value"),
      description: t("section_about_us.strengths.s1.desc"),
    },
    {
      id: 2,
      title: t("section_about_us.strengths.s2.title"),
      value: t("section_about_us.strengths.s2.value"),
      description: t("section_about_us.strengths.s2.desc"),
    },
    {
      id: 3,
      title: t("section_about_us.strengths.s3.title"),
      value: t("section_about_us.strengths.s3.value"),
      description: t("section_about_us.strengths.s3.desc"),
    },
    {
      id: 4,
      title: t("section_about_us.strengths.s3.title"),
      value: t("section_about_us.strengths.s3.value"),
      description: t("section_about_us.strengths.s3.desc"),
    },
  ];

  const strenghtsData = useMemo(() => strenghts(), [i18n.language]);

  return (
    <>
      <section className="section" id="about-us">
        <Container>
          <div style={{ background: `url(${MapImage}) center center` }}>
            <Row className="align-items-center">
              <Col lg={6} md={6}>
                <div className="position-relative me-lg-5">
                  <img
                    src={AboutImage}
                    className="rounded img-fluid mx-auto d-block"
                    alt=""
                  />
                  <div className="play-icon">
                    <Link
                      to="#"
                      onClick={openModal}
                      data-type="youtube"
                      data-id="yba7hPeTSjk"
                      className="play-btn lightbox"
                    >
                      <i className="mdi mdi-play text-primary rounded-circle bg-white shadow"></i>
                    </Link>
                  </div>
                </div>
              </Col>
              <Col lg={6} md={6} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div className="section-title">
                  <h4 className="title mb-3">
                    {t("section_about_us.title.t1")} <br />{" "}
                    {t("section_about_us.title.t2")}
                  </h4>
                  <p className="text-muted">{t("section_about_us.desc.d1")}</p>
                  <p className="text-muted">{t("section_about_us.desc.d2")}</p>
                  <p className="text-muted">{t("section_about_us.desc.d3")}</p>
                </div>
              </Col>
            </Row>
            <Row className="equal-height-cards">
              {strenghtsData.map((item, key) => (
                <Col lg={3} md={6} className="col-12 mt-4 pt-2" key={key}>
                  <Card className="border-0 shadow d-flex flex-column h-100">
                    {" "}
                    {/* Add h-100 for full height */}
                    <div className="p-4">
                      <span className="price h3 fw-semibold mb-0">
                        {item.value}
                      </span>
                      <h6 className="fw-semibold mb-3 text-uppercase">
                        {item.title}
                      </h6>
                      <p className="text-muted flex-grow-1">
                        {item.description}
                      </p>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        </Container>
      </section>
      <ModalVideo
        channel="youtube"
        isOpen={videoModal}
        youtube={{
          autoplay: 0,
        }}
        videoId="m76VIHXs1QY"
        onClose={() => setVideoModal(false)}
      />
    </>
  );
}
